import React, { useEffect, useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
import {
  RiFacebookFill,
  RiTwitterFill,
  RiInstagramFill,
  RiYoutubeFill,
  RiHome2Fill,
  RiSearch2Fill,
  RiMovie2Fill,
  RiListSettingsFill,
} from "react-icons/ri";
import "./Footer.css"; // Import custom CSS for footer styling
import { Link, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Button } from "react-bootstrap";

function isMobileDevice() {
  return window.matchMedia("(max-width: 1000px)").matches;
}
const pathname = window.location.pathname;
const urlParts = pathname.split("/");
const lastElement = urlParts[urlParts.length - 1];

const Footer = () => {
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [showFooter, setShowFooter] = useState(true);
  const [activeKey, setActiveKey] = useState("/");
  const location = useLocation();
  // const handleSelect = (selectedKey) => {
  //   setActiveKey(selectedKey);
  // };

  useEffect(() => {
    if (
      lastElement === "subscription" ||
      lastElement === "quick_subscribe" ||
      lastElement === "payment_status" ||
      lastElement === "explayer"
    ) {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
    if (location.pathname === "/") {
      setActiveKey("/");
    } else if (location.pathname === "/browse") {
      setActiveKey("/browse");
    } else if (location.pathname === "/upcoming") {
      setActiveKey("/upcoming");
    } else if (location.pathname === "/subscribe") {
      setActiveKey("/subscribe");
    }
  }, [location.pathname, activeKey, showFooter]);

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    // if (window.location.pathname === "/") {
    //   //console.log("window.location.pathname", window.location.pathname);
    //   setActiveKey("/");
    // } else if (window.location.pathname === "/browse") {
    //   setActiveKey("/browse");
    // } else if (window.location.pathname === "/upcoming") {
    //   setActiveKey("/upcoming");
    // } else if (window.location.pathname === "/subscribe") {
    //   setActiveKey("/subscribe");
    // }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.location.pathname, activeKey]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showFooter && (
        <>
          {isMobile ? (
            <div className="bottom-tab-bar">
              <Button
                evenKey="/"
                onClick={() => setActiveKey("/")}
                as={Link}
                to="/"
                className="tab-item"
                style={{
                  borderWidth: "0",
                  borderRadius: "20px",
                  backgroundColor: activeKey === "/" ? "#007cc2" : "#007cc200",
                }}
              >
                <RiHome2Fill /> {activeKey === "/" ? "Home" : ""}
              </Button>

              <Button
                evenKey="/browse"
                onClick={() => setActiveKey("/browse")}
                as={Link}
                to="/browse"
                className="tab-item"
                style={{
                  borderWidth: "0",
                  borderRadius: "20px",
                  backgroundColor:
                    activeKey === "/browse" ? "#007cc2" : "#007cc200",
                }}
              >
                <RiSearch2Fill /> {activeKey === "/browse" ? "Browse" : ""}
              </Button>

              <Button
                onClick={() => setActiveKey("/upcoming")}
                as={Link}
                to="/upcoming"
                className="tab-item"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  borderWidth: "0",
                  borderRadius: "20px",
                  backgroundColor:
                    activeKey === "/upcoming" ? "#007cc2" : "#007cc200",
                }}
              >
                <RiMovie2Fill />
                {activeKey === "/upcoming" ? "Upcoming" : ""}
              </Button>

              <Button
                onClick={() => setActiveKey("/appsettings")}
                as={Link}
                to="/appsettings"
                className="tab-item"
                style={{
                  borderWidth: "0",
                  borderRadius: "20px",
                  backgroundColor:
                    activeKey === "/appsettings" ? "#007cc2" : "#007cc200",
                }}
              >
                <RiListSettingsFill />{" "}
                {activeKey === "/appsettings" ? "Settings" : ""}
              </Button>
              <div className="scroll-to-top" onClick={scrollToTop}>
                <FaArrowCircleUp />
              </div>
            </div>
          ) : (
            <footer
              className="footer"
              style={{
                bg: "dark",
                variant: "dark",
                height: "auto",
                paddingTop: "20px",
              }}
            >
              {/* <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <h4
                      className="footerHeading"
                      style={{
                        color: "#ffffff",
                        borderBottom: "1px solid #ffffff",
                        paddingBottom: "8px",
                      }}
                    >
                      Explore
                    </h4>
                    <ul className="custom-bullet">
                      <li className="nav-item">
                        <a className="nav-link" href="/">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/browse">
                          Browse
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/subscribe">
                          Subscribe
                        </a>
                      </li>
                      {/* <li className="nav-item">
                    <a className="nav-link" href="/submit-content">
                      Submit Content
                    </a>
                  </li> }
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <h4
                      className="footerHeading"
                      style={{
                        color: "#ffffff",
                        borderBottom: "1px solid #ffffff",
                        paddingBottom: "8px",
                      }}
                    >
                      Quick Links
                    </h4>
                    <ul className="custom-bullet">  
                      <li className="nav-item">
                        <a className="nav-link" href="/about-us">
                          About Us
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/contact-us">
                          Contact Us
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/support">
                          Support
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/CookiePolicy">
                          Cookie Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <h4
                      className="footerHeading"
                      style={{
                        color: "#ffffff",
                        borderBottom: "1px solid #ffffff",
                        paddingBottom: "8px",
                      }}
                    >
                      T&M
                    </h4>
                    <ul className="custom-bullet">
                      <li className="nav-item">
                        <a className="nav-link" href="/privacy">
                          Privacy Policy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/terms-and-conditions">
                          Terms &amp; Conditions
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/refund-policy">
                          Refund Policy
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/delete-user-policy">
                          Delete User Policy
                        </a>
                      </li>
                      {/* <li className="nav-item"><a className="nav-link" href="/medialanding">TEST</a></li> }
                    </ul>
                  </div>
                  <div className="col-md-3">
                    <h4 className="footerHeading">Follow us On</h4>
                    {/* <div className="btn-wrapper profile">
                      <a
                        href="#"
                        className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          marginRight: "5px",
                        }}
                      >
                        <RiFacebookFill style={{ color: "#007cc2" }} />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          marginRight: "5px",
                        }}
                      >
                        <RiTwitterFill style={{ color: "#007cc2" }} />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          marginRight: "5px",
                        }}
                      >
                        <RiYoutubeFill style={{ color: "#007cc2" }} />
                      </a>
                      <a
                        href="#"
                        className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        style={{
                          borderRadius: "5px",
                          backgroundColor: "white",
                          marginRight: "5px",
                        }}
                      >
                        <RiInstagramFill style={{ color: "#007cc2" }} />
                      </a>
                    </div> }

                    <div className="btn-wrapper profile">
                      <a
                        href="#"
                        // className="btn btn-icon btn-neutral btn-round btn-simple social-button facebook"
                        className="btn social-button-face facebook"
                        style={{
                          borderRadius: "5px",
                          //backgroundColor: "#EC1067",
                          marginRight: "5px",
                        }}
                      >
                        <RiFacebookFill style={{ color: "#007cc2" }} />
                      </a>
                      {/* <a
                        href="#"
                        // className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        className="btn social-button-twit twitter"
                        style={{
                          borderRadius: "5px",
                          // backgroundColor: "#2F96C5",
                          marginRight: "5px",
                        }}
                      >
                        <RiTwitterFill style={{ color: "#007cc2" }} />
                      </a> }
                      <a
                        href="#"
                        className="btn social-button-twit twitter"
                        style={{
                          borderRadius: "5px",
                          marginRight: "5px",
                          display: "inline-block",
                          position: "relative", // Make sure the image stays in place
                        }}
                      >
                        <img
                          src={"./new-icon-twitter.png"}
                          alt={"./new-icon-twitter.png"}
                          // className="twitter-icon"
                          style={{ height: "17px", width: "17px" }}
                          onMouseOver={(e) =>
                            (e.currentTarget.src = "./new-icon-twitter.png")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.src = "./new-icon-twitter.png")
                          }
                        />
                      </a>
                      <a
                        href="#"
                        // className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        className="btn social-button-you youtube"
                        style={{
                          borderRadius: "5px",
                          // backgroundColor: "#00A151",
                          marginRight: "5px",
                        }}
                      >
                        <RiYoutubeFill style={{ color: "#007cc2" }} />
                      </a>
                      <a
                        href="#"
                        // className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        className="btn social-button-insta instagram"
                        style={{
                          borderRadius: "5px",
                          // backgroundColor: "#65009C",
                          marginRight: "5px",
                        }}
                      >
                        <RiInstagramFill style={{ color: "#007cc2" }} />
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <ul
                      className="custom-bullet d-flex align-items-center flex-wrap"
                      style={{
                        listStyleType: "none",
                        padding: 0,
                        margin: 0,
                        marginTop: "-25px",
                      }}
                    >
                      <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/">
                          Home
                        </a>
                      </li>
                      <span
                        style={{
                          marginRight: "10px",
                          color: "#ffffff",
                        }}
                      >
                        |
                      </span>
                      <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/browse">
                          Browse
                        </a>
                      </li>
                      <span style={{ marginRight: "10px", color: "#ffffff" }}>
                        |
                      </span>
                      {/* <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/subscribe">
                          Subscribe
                        </a>
                      </li>
                      <span style={{ marginRight: "10px", color: "#ffffff" }}>
                        |
                      </span> */}
                      <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/about-us">
                          About Us
                        </a>
                      </li>
                      <span style={{ marginRight: "10px", color: "#ffffff" }}>
                        |
                      </span>
                      <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/contact-us">
                          Contact Us
                        </a>
                      </li>
                      <span style={{ marginRight: "10px", color: "#ffffff" }}>
                        |
                      </span>
                      <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/support">
                          Support
                        </a>
                      </li>
                      <span style={{ marginRight: "10px", color: "#ffffff" }}>
                        |
                      </span>
                      <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/CookiePolicy">
                          Cookie Policy
                        </a>
                      </li>
                    </ul>

                    <div
                      className="btn-wrapper profile d-flex align-items-center"
                      style={{ marginLeft: "20rem", marginTop: "-8px" }}
                    >
                      <a
                        href="#"
                        // className="btn btn-icon btn-neutral btn-round btn-simple social-button facebook"
                        className="btn social-button-face facebook"
                        style={{
                          borderRadius: "5px",
                          //backgroundColor: "#EC1067",
                          marginRight: "5px",
                        }}
                      >
                        <RiFacebookFill
                          className="icon1"
                          style={{ color: "#007cc2" }}
                        />
                      </a>
                      <a
                        href="#"
                        className="btn social-button-twit twitter"
                        style={{
                          borderRadius: "5px",
                          marginRight: "5px",
                          display: "inline-block",
                          position: "relative", // Make sure the image stays in place
                        }}
                      >
                        <img
                          src={"./new-icon-twitter.png"}
                          alt={"./new-icon-twitter.png"}
                          // className="twitter-icon"
                          style={{ height: "17px", width: "17px" }}
                          onMouseOver={(e) =>
                            (e.currentTarget.src = "./new-icon-twitter.png")
                          }
                          onMouseOut={(e) =>
                            (e.currentTarget.src = "./new-icon-twitter.png")
                          }
                        />
                      </a>
                      <a
                        href="#"
                        // className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        className="btn social-button-you youtube"
                        style={{
                          borderRadius: "5px",
                          // backgroundColor: "#00A151",
                          marginRight: "5px",
                        }}
                      >
                        <RiYoutubeFill
                          className="icon3"
                          style={{ color: "#007cc2" }}
                        />
                      </a>
                      <a
                        href="#"
                        // className="btn btn-icon btn-neutral btn-round btn-simple mr-2"
                        className="btn social-button-insta instagram"
                        style={{
                          borderRadius: "5px",
                          // backgroundColor: "#65009C",
                          marginRight: "5px",
                        }}
                      >
                        <RiInstagramFill
                          className="icon4"
                          style={{ color: "#007cc2" }}
                        />
                      </a>
                    </div>
                  </div>

                  <div
                    className="col-6 d-flex justify-content-between align-items-center"
                    style={{ marginTop: "-15px" }}
                  >
                    <ul
                      className="custom-bullet d-flex align-items-center flex-wrap"
                      style={{ listStyleType: "none", padding: 0, margin: 0 }}
                    >
                      <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/privacy">
                          Privacy Policy
                        </a>
                      </li>
                      <span style={{ marginRight: "10px", color: "#ffffff" }}>
                        |
                      </span>
                      <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/terms-and-conditions">
                          Terms &amp; Conditions
                        </a>
                      </li>
                      <span style={{ marginRight: "10px", color: "#ffffff" }}>
                        |
                      </span>
                      <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/refund-policy">
                          Refund Policy
                        </a>
                      </li>
                      <span style={{ marginRight: "10px", color: "#ffffff" }}>
                        |
                      </span>
                      <li
                        className="nav-item"
                        style={{ marginRight: "10px", padding: 0 }}
                      >
                        <a className="nav-link" href="/delete-user-policy">
                          Delete User Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="scroll-to-top" onClick={scrollToTop}>
                <FaArrowCircleUp />
              </div>
            </footer>
          )}
        </>
      )}
    </>
  );
};

{
  /* <>

{isMobile ? (
        // Render your mobile view here
        <div className="bottom-tab-bar"> 
    <div>
    <Link to="/" className="tab-item">Home</Link>
    <Link to="/browse" className="tab-item">Browse</Link>
    <Link to="/upcoming" className="tab-item">Upcoming</Link>
    <Link to="/contact" className="tab-item">Contact</Link>    
    <div className="scroll-to-top" onClick={scrollToTop}>
        <FaArrowCircleUp />
      </div> 
</div>
      ) : (
        
        <>
        
      )}    
      </> 
  );
</>
}; */
}

export default Footer;
