import { Link, useLocation, withRouter } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import "./Player/Player.css";
import instance from "./common/Api";
import { useCookies } from "react-cookie";
// import VideoJsPlayer from "./VideoJsPlayer";
import Cookies from "js-cookie";
import Loader from "./common/Loader";
import VideoJsPlayer from "./Player/VideoJsPlayer";

const Explayer = (props) => {
  const location = useLocation();
  //   const { someData, type } = location.state;
  //   const { src, poster } = location.state;
  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [mediaData, setMediaData] = useState(null);
  const [currentVideoImg, setCurrentVideoImg] = useState("");
  const [cookies, setCookies] = useCookies();
  function isMobileDevice() {
    return window.matchMedia("(max-width: 1200px)").matches;
  }

  const [videoType, setVideoType] = useState(null);
  var counter = 1;

  const set_Cookies = (key, value, options) => {
    Cookies.set(key, value, options);
  };

  useEffect(() => {
    const urlString = window.location.href;
    // const url = "https://www.bebu.app/explayer?raj3DhUbMcYz36HpRbezhWERbNsKtX/MTQ2/V2hhdCBUaGUgRiEhIQ==";

    const queryString = location.search;
    const queryParam = queryString ? queryString.substring(1) : "";
    const dynamicCode = queryParam.split("/")[0];
    console.log(dynamicCode, "CODE");

    const isValidBase64 = (str) => {
      try {
        // Check if the string is properly padded and does not have invalid characters
        return btoa(atob(str)) === str;
      } catch (err) {
        return false;
      }
    };
    const extractBase64Strings = (urlString) => {
      const queryString = location.search; // Get the query string part (everything after ?)
      const queryParam = queryString ? queryString.substring(1) : ""; // Remove the '?' from the query string
      const base64Parts = queryParam.split("/"); // Split the query param by '/'
      return base64Parts.filter((str) => isValidBase64(str)); // Only return valid Base64 parts
    };
    const decodeBase64 = (base64Str) => {
      try {
        return atob(base64Str);
      } catch (error) {
        console.error("Error decoding Base64 string:", error);
        return "Invalid Base64 string";
      }
    };
    const base64Strings = extractBase64Strings(urlString);
    const decodedStrings = base64Strings.map((str) => decodeBase64(str));
    console.log(decodedStrings, "DECODE");

    checkCodeApi(dynamicCode, decodedStrings);

    const interval = setInterval(() => {
      checkCodeApi(dynamicCode, decodedStrings);
    }, 120000);

    return () => clearInterval(interval);
  }, []);

  const checkCodeApi = (dynamicCode, decodedStrings) => {
    instance
      .post(
        `/checkTemporaryCode?code=${dynamicCode}&videoid=${
          decodedStrings[1]
        }&id=${"1"}&FINGERPRINT=${window.navigator.userAgent}`
      )
      .then((response) => {
        console.log(response.data, "Response");
        // setCheck(response.status);

        if (response.data.status) {
          if (counter < 2) {
            counter++;
            callVideoApi(
              decodedStrings[1],
              decodedStrings[0],
              decodedStrings[2]
            );
          }
        } else {
          window.location.href = "/";
        }
      })
      .catch((error) => {
        window.location.href = "/";
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const callVideoApi = (vidTitle, vidId, isTrailerOrVid) => {
    instance
      .get(
        `/videoViewBySlug?titleYearSlug=${vidTitle}&videoid=${vidId}&ip=${props.ip}`
      )
      .then((response) => {
        // console.log(response);
        setMediaData(
          isTrailerOrVid == 0
            ? response.data.video
            : response.data.trailer_video
        );
        setCurrentVideoImg(mediaData && mediaData.mobile_image);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when fetch is complete
      });
  };

  useEffect(() => {
    const setNewCookies = async () => {
      try {
        instance
          .get(`/getAllCookies`)
          .then((response) => {
            const cookies = response.data;
            console.log("getAllCookies.....", cookies);
            cookies.forEach((obj) => {
              const { key, value } = obj;
              // set_Cookies(key, value, { path: "/play", domain: "bebu.app" });
              setCookies(key, value, { path: "/", domain: "ubeetu.com" });
            });
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        // Fetch data from an endpoint (example)
      } catch (error) {
        console.error("Error fetching or setting cookies:", error);
      }
    };

    setNewCookies();
  }, []);

  // useEffect(() => {
  //   setLoading(true);
  //   (async function () {
  //     let response = await instance.get("/getAllCookies");

  //     response.data.forEach((obj) => {
  //       setLoading(false);
  //       const { key, value } = obj;
  //       setCookie(key, value, { path: "/play", domain: "bebu.app" });
  //     });
  //     //console.log(cookies);
  //   })();
  // }, []);

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    // setVideoUrl(
    //   isMobile
    //     ? videoType === "main"
    //       ? someData.video
    //       : someData.trailer_video
    //     : src
    // );
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [mediaData]);

  const handleVideoChange = (newVideoUrl) => {
    // setVideoUrl(newVideoUrl);
  };

  return (
    <div className="">
      {/* {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
        </div>
      )} */}
      {mediaData && (
        <>
          {isMobile ? (
            <VideoJsPlayer
              src={mediaData}
              poster={currentVideoImg}
              className="react-player"
              isMobile={isMobile}
              handleVideoChange={handleVideoChange}
              isFromExplayer={true}
              // userData={userData}
              // COOKIES={newCookies}
            />
          ) : (
            <VideoJsPlayer
              // playerRef={player}
              src={mediaData}
              poster={currentVideoImg}
              className="react-player"
              isMobile={false}
              handleVideoChange={handleVideoChange}
              isFromExplayer={true}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Explayer;
