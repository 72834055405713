import { Link, useLocation, withRouter } from "react-router-dom";
import React, { useRef, useState, useEffect } from "react";
import "./Player.css";
import screenfull from "screenfull";
import { Col, Row, Dropdown, Button, Card } from "react-bootstrap";
import ReactPlayer from "react-player";
import { Slider, Direction } from "react-player-controls";
import {
  BsFillCaretRightFill,
  BsFillShareFill,
  BsFillCaretLeftFill,
  BsBack,
  BsFillArrowLeftSquareFill,
} from "react-icons/bs"; // Import the BsFilter icon
// import axios from "axios";
// import Cookies from "js-cookie";
import instance from "../common/Api";
import { useCookies } from "react-cookie";
import VideoJsPlayer from "./VideoJsPlayer";
import WebPlayer from "./WebPlayer";
import MobilePlayer from "./MobilePlayer";
import Cookies from "js-cookie";

// import CommonListing from '../common/CommonListing';

const Player = (props) => {
  const location = useLocation();
  const { someData, type } = location.state;
  const { src, poster } = location.state;
  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies();
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  function isMobileDevice() {
    return window.matchMedia("(max-width: 1200px)").matches;
  }
  // const [isVisible, setIsVisible] = useState(false);
  const [videoType, setVideoType] = useState(null);
  console.log(someData, "FROMPLAYER");
  // const [state, setState] = useState({
  //   url: null,
  //   pip: false,
  //   playing: false,
  //   controls: false,
  //   light: false,
  //   volume: 0.8,
  //   muted: false,
  //   played: 0,
  //   loaded: 0,
  //   duration: 0,
  //   playbackRate: 1.0,
  //   loop: false,
  //   volumeOpen: false,
  //   dropdownOpen: false,
  //   fullscreen: false,
  // });

  // // const [bebucookies, setBebuCookies] = useState(null);
  // // const [cookies, setCookie] = useCookies();
  // let timeout;
  // const handleApiResponse = (response) => {
  //   // Assuming the response is an array of objects
  //   response.forEach((obj) => {
  //     const { key, value } = obj;
  //     // console.info(key);
  //     // console.info(value);
  //     setCookie(key, value); // Set the cookie with the key and value from the API response
  //   });
  // };

  // useEffect(() => {
  //   let timeout;
  //   if (isVisible && state.playing) {
  //     timeout = setTimeout(() => {
  //       setIsVisible(false);
  //     }, 5000); // 5 seconds in milliseconds
  //   }

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [isVisible, state.playing]);

  const set_Cookies = (key, value, options) => {
    Cookies.set(key, value, options);
  };
  useEffect(() => {
    const setNewCookies = async () => {
      try {
        instance
          .get(`/getAllCookies`)
          .then((response) => {
            const cookies = response.data;
            // console.log("getAllCookies.....", cookies);
            cookies.forEach((obj) => {
              const { key, value } = obj;
              set_Cookies(key, value, { path: "/play", domain: "ubeetu.com" });
            });
            // Object.entries(cookies).forEach(([key, value]) => {
            //   document.cookie = `${key}=${value}; path=/play`;
            // });
            // setNewCookies(cookies);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        // Fetch data from an endpoint (example)
      } catch (error) {
        console.error("Error fetching or setting cookies:", error);
      }
    };

    setNewCookies();
  }, []);

  useEffect(() => {
    (async function () {
      let response = await instance.get("/getAllCookies");

      response.data.forEach((obj) => {
        const { key, value } = obj;
        setCookie(key, value, { path: "/play", domain: "ubeetu.com" });
      });
      //console.log(cookies);
    })();
  }, []);

  useEffect(() => {
    // console.log("src:", src, "poster:", poster);
    // if (isMobile) {
    // }
    // if (type !== null) {
    //   setVideoType(type);
    //   console.log("someData:", someData, "type:", videoType);
    // }

    // (async function () {
    //   let response = await instance.get("/getAllCookies");
    //   console.log("res", response);
    //   response.data.forEach((obj) => {
    //     const { key, value } = obj;
    //     setCookie(key, value);
    //   });
    //   setLoading(false);
    //   // Contnet
    // })();

    function handleResize() {
      setIsMobile(isMobileDevice());
    }
    // if (
    //   cookies["CloudFront-Key-Pair-Id"] &&
    //   cookies["CloudFront-Policy"] &&
    //   cookies["CloudFront-Signature"]
    // ) {
    //   setVideoUrl(
    //     isMobile
    //       ? videoType === "main"
    //         ? someData.video
    //         : someData.trailer_video
    //       : src
    //   );

    //   // someData.video
    //   // "https://testmedia.bebu.app/vidoes/anamoly/ep04/playlist.m3u8" //type === "main" ? someData.video : someData.trailer_video // "https://media1.bebu.app/videos/WhatTheF/Trailer/playlist.m3u8"
    //   //
    // }
    setVideoUrl(
      isMobile
        ? videoType === "main"
          ? someData.video
          : someData.trailer_video
        : src
    );
    console.log(videoUrl);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [videoUrl]);

  // const player = useRef(null);
  // const playerWrapper = useRef(null);

  // const load = (url) => {
  //   setState({
  //     ...state,
  //     url,
  //     played: 0,
  //     loaded: 0,
  //     pip: false,
  //     playing: true,
  //   });
  // };

  // const handlePlayPause = () => {
  //   setState({ ...state, playing: !state.playing });
  // };

  // const [hovered, setHovered] = useState(false);
  // // const history = useHistory();
  // const handleMouseEnter = () => {
  //   if (state.playing) {
  //     setHovered(true);
  //   }
  // };

  // const handleMouseLeave = () => {
  //   setHovered(false);
  // };

  // const handleButtonClick = () => {
  //   // history.push('/play');
  // };

  // const skipBackward = (event) => {
  //   event.preventDefault();
  //   if (player.current) {
  //     const currentTime = player.current.getCurrentTime();
  //     const newTime = currentTime - 10;
  //     player.current.seekTo(newTime, "seconds");
  //     // setState({ ...state, playing: true });
  //   }
  // };

  // const skipForward = (event) => {
  //   event.preventDefault();
  //   if (player.current) {
  //     const currentTime = player.current.getCurrentTime();
  //     const newTime = currentTime + 10;
  //     player.current.seekTo(newTime, "seconds");
  //     // setState({ ...state, playing: true });
  //   }
  // };
  // const formatGenresWeb = (genres) => {
  //   if (!genres || genres.length === 0) {
  //     return null;
  //   }

  //   return genres.join(" | ");
  // };
  // const getCastNames = (cast) => {
  //   return cast.map((item) => item.name).join(" | ");
  // };

  // const handleClick = () => {
  //   setIsVisible(!isVisible);
  // };

  // useEffect(() => {
  //   let timeout;
  //   if (isVisible && state.playing) {
  //     timeout = setTimeout(() => {
  //       setIsVisible(false);
  //     }, 5000); // 5 seconds in milliseconds
  //   }

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [isVisible, state.playing]);

  // const captions = [
  //   {
  //     kind: "captions",
  //     src: "https://example.com/captions.vtt",
  //     srcLang: "en",
  //     label: "English",
  //   },
  // ];

  return (
    <div className="">
      {/* {videoUrl && ( */}
      <>
        {isMobile ? (
          <MobilePlayer
            userData={props.userData}
            someData={someData}
            type={type}
          />
        ) : (
          // <WebPlayer someData={someData} type={type} />
          <WebPlayer src={src} poster={poster} />
        )}
      </>
      {/* )} */}
    </div>
  );
};

export default Player;

// <ReactPlayer
//   className="react-player"
//   ref={player}
//   url={videoUrl}
//   width="100%"
//   height="100%"
//   pip={state.pip}
//   playing={state.playing}
//   controls={true}
//   loop={state.loop}
//   playbackRate={state.playbackRate}
//   volume={state.volume}
//   muted={state.muted}
//   onStart={() =>
//     setState({ ...state, visible_button_refresh: true })
//   }
//   onPlay={handlePlay}
//   onEnablePIP={handleEnablePIP}
//   onDisablePIP={handleDisablePIP}
//   onPause={handlePause}
//   onBuffer={() => console.log("onBuffer")}
//   onSeek={(e) => console.log("onSeek", e)}
//   onEnded={handleEnded}
//   onError={(e) => console.log("onError", e)}
//   onProgress={handleProgress}
//   onDuration={handleDuration}
//   onReady={() => handlePlayPause()}
//   config={{
//     // file: {
//     //   tracks: captions,
//     // },
//     attributes: {
//       crossOrigin: "use-credentials",
//     },
//   }}
// />

// import React, { useEffect, useRef, useState } from "react";
// import VideoPlayer from "react-video-js-player";
// import { useCookies } from "react-cookie";
// import instance from "../common/Api";
// import ReactPlayer from "react-player";
// const Player = () => {
//   const [cookies, setCookie] = useCookies([
//     "CloudFront-Key-Pair-Id",
//     "CloudFront-Policy",
//     "CloudFront-Signature",
//   ]);
//   const [videoUrl, setVideoUrl] = useState(null);

//   const playerRef = useRef();
//   // const handleApiResponse = (response) => {
//   //   // Assuming the response is an array of objects
//   //   response.forEach((obj) => {
//   //     const { key, value } = obj;
//   //     // console.info(key);
//   //     // console.info(value);
//   //     setCookie(key, value); // Set the cookie with the key and value from the API response
//   //   });
//   // };

//   useEffect(() => {
//     (async function () {
//       let response = await instance.get("/getAllCookies");
//       console.log("res", response);
//       response.data.forEach((obj) => {
//         const { key, value } = obj;
//         setCookie(key, value);
//       });
//       // setLoading(false);
//       // Contnet
//     })();
//     // Assume that the cookies are set before setting the video URL
//     if (
//       cookies["CloudFront-Key-Pair-Id"] &&
//       cookies["CloudFront-Policy"] &&
//       cookies["CloudFront-Signature"]
//     ) {
//       setVideoUrl(
//         "https://media1.bebu.app/videos/WhatTheF/Trailer/playlist.m3u8" // "https://media1.bebu.app/videos/WhatTheF/Trailer/playlist.m3u8"
//       );
//     }
//   }, [cookies]);

//   return (
//     <div>
//       {videoUrl && (
//         <ReactPlayer
//           url={videoUrl}
//           autoPlay={true}
//           controls={true}
//           width="1200"
//           height="auto"
//           ref={playerRef}
//           config={{
//             file: {
//               attributes: {
//                 crossorigin: "use-credentials",
//                 headers: {
//                   Cookie: `Cookies=${cookies}`,
//                 },
//               },
//             },
//           }}
//         />
//         // <VideoPlayer
//         //   src={""}
//         //   controls
//         //   autoplay
//         //   fluid
//         //   width="100%"
//         //   height="auto"
//         //   poster="https://example.com/poster.jpg"
//         // />
//       )}
//     </div>
//   );
// };

// export default Player;

// <div className="player-component">
//   <Row className="justify-content-center h-100 align-items-center">
//     <Col xs="12" lg="7" className="player-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//       <div className="back-button" onClick={handleButtonClick}>
//         <BsFillCaretLeftFill />
//       </div>
//       <div className="player-wrapper">
//         <ReactPlayer
//           className="react-player"
//           ref={player}
//           url={state.url}
//           width="100%"
//           height="100%"
//           pip={state.pip}
//           playing={state.playing}
//           controls={true}
//           loop={state.loop}
//           playbackRate={state.playbackRate}
//           volume={state.volume}
//           muted={state.muted}
//           onStart={() => setState({ ...state, visible_button_refresh: true })}
//           onPlay={handlePlay}
//           onEnablePIP={handleEnablePIP}
//           onDisablePIP={handleDisablePIP}
//           onPause={handlePause}
//           onBuffer={() => console.log("onBuffer")}
//           onSeek={(e) => console.log("onSeek", e)}
//           onEnded={handleEnded}
//           onError={(e) => console.log("onError", e)}
//           onProgress={handleProgress}
//           onDuration={handleDuration}
//           onReady={() => handlePlayPause()}
//         />
//         {hovered && (
//           <div className="overlay-player">
//             <button className="skip-button" onClick={skipBackward}>
//               <BsSkipStartFill />
//             </button>
//             <button className="skip-button" onClick={handlePlayPause}>
//               {state.playing ? <BsFillPauseBtnFill /> : <BsFillCaretRightFill />}
//             </button>
//             <button className="skip-button" onClick={skipForward}>
//               <BsSkipEndFill />
//             </button>
//           </div>
//         )}
//       </div>
//     </Col>
//   </Row>
// </div>

//react functions

// const handleStop = () => {
//   setState({ ...state, url: null, playing: false });
// };

// const handleToggleControls = () => {
//   const { url } = state;
//   setState(
//     {
//       ...state,
//       controls: !state.controls,
//       url: null,
//     },
//     () => this.load(url)
//   );
// };

// const handlePlay = () => {
//   console.log("onPlay");
//   if (!state.playing) {
//     setState({ ...state, playing: true });
//   }
// };

// const handleEnablePIP = () => {
//   console.log("onEnablePIP");
//   setState({ ...state, pip: true });
// };

// const handleDisablePIP = () => {
//   console.log("onDisablePIP");
//   setState({ ...state, pip: false });
// };

// const handlePause = () => {
//   console.log("onPause");
//   setState({ ...state, playing: false });
// };

// const handleSeekMouseDown = (e) => {
//   setState({ ...state, seeking: true });
// };

// const handleSeekChange = (e) => {
//   setState({ ...state, played: parseFloat(e.target.value) });
// };

// const handleSeekMouseUp = (e) => {
//   setState({ ...state, seeking: false });
//   if (player?.current) {
//     player.current.seekTo(parseFloat(e.target.value));
//   }
// };

// const handleProgress = (stateIn) => {
//   console.log("onProgress", state.playbackRate);
//   // We only want to update time slider if we are not currently seeking
//   if (!state.seeking) {
//     setState({ ...state, ...stateIn });
//   }
// };

// const handleEnded = () => {
//   console.log("onEnded");
//   setState({ ...state, playing: false });
// };

// const handleDuration = (duration) => {
//   console.log("onDuration", duration);
//   setState({ ...state, duration });
// };

// const toggleVolume = () => {
//   setState({ ...state, volumeOpen: !state.volumeOpen });
// };

// const toggleDropdown = (value) => {
//   setState({ ...state, dropdownOpen: !state.dropdownOpen });
// };

// const handleClickFullscreen = () => {
//   if (playerWrapper?.current) {
//     if (screenfull.isFullscreen) {
//       screenfull.exit(playerWrapper.current);
//     } else {
//       screenfull.request(playerWrapper.current);
//     }
//   }
//   setState({ ...state, fullscreen: !state.fullscreen });
// };

// const SliderBar = ({ direction, value, style }) => (
//   <div
//     style={Object.assign(
//       {},
//       {
//         position: "absolute",
//         background: "GRAY",
//         borderRadius: 4,
//       },
//       direction === Direction.HORIZONTAL
//         ? {
//             top: 0,
//             bottom: 0,
//             left: 0,
//             width: `${value * 100}%`,
//           }
//         : {
//             right: 0,
//             bottom: 0,
//             left: 0,
//             height: `${value * 100}%`,
//           },
//       style
//     )}
//   />
// );
